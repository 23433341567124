<template>
    <order-list type="userCar"></order-list>
</template>

<script type="text/ecmascript-6">
    import OrderList from '../orderList/index'

    export default {
        name: 'userCarList',
        components: {OrderList}
    }
</script>